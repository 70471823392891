import React, { useContext, useState } from "react";
import Modal from "./components/Modal";

import CRM from "./CRM";
import { SectionProps } from "./SectionProps";

const Anmeldung = (props: SectionProps & { programToShow?: string }) => {
	const lang = useContext(CRM).language;
	const [showModal, setShowModal] = useState(false);
	return (
		<section id={props.id} className="main style2 left dark fullscreen">
			<Modal show={showModal} onClose={() => setShowModal(false)} title="Preise 2023">
				<></>
				<table>
					<thead>
						<tr style={{ fontWeight: "bold" }}>
							<th>&nbsp;</th>
							<th>Doppelzimmer</th>
							<th>Mehrbettzimmer</th>
							<th>Tagesgast</th>
							<th>Wohnmobil/Zelt</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>0-3 Jahre</td>
							<td>6 €</td>
							<td>6 €</td>
							<td>6 €</td>
							<td>6 €</td>
						</tr>
						<tr>
							<td>4-6 Jahre</td>
							<td>35 €</td>
							<td>27 €</td>
							<td>16 €</td>
							<td>26 €</td>
						</tr>
						<tr>
							<td>7-17 Jahre</td>
							<td>70 €</td>
							<td>57 €</td>
							<td>32 €</td>
							<td>51 €</td>
						</tr>
						<tr>
							<td>18+ Jahre</td>
							<td>90 €</td>
							<td>67 €</td>
							<td>41 €</td>
							<td>60 €</td>
						</tr>
					</tbody>
				</table>
			</Modal>
			<div className="content box style2">
				<header>
					<h2>{crm[lang].Anmeldung}</h2>
				</header>
				{crm[lang].Text}
				<p>
					{/*<a
						onClick={() => setShowModal(true)}
						className="button"
						style={{ lineHeight: "1.5em", height: "1.5em" }}
					>
						{crm[lang].Preise}
					</a>*/}
					&nbsp;
					<a
						href={
							crm[lang].Anmeldeformular.indexOf("email") === -1
								? "./downloads/Voranmeldung Ostara 2025.pdf"
								: "#contact"
						}
						className="button"
						style={{ lineHeight: "1.5em", height: "1.5em" }}
					>
						{crm[lang].Anmeldeformular}
					</a>
					&nbsp;
					<a
						href={crm[lang].Anmeldeformular.indexOf("AGB") === -1 ? "./downloads/agb.pdf" : "#contact"}
						className="button"
						style={{ lineHeight: "1.5em", height: "1.5em" }}
					>
						{crm[lang].AGB}
					</a>
					&nbsp;
					{props.programToShow && (
						<>
							<a
								href={"./downloads/" + props.programToShow}
								className="button"
								style={{ lineHeight: "1.5em", height: "1.5em" }}
							>
								{crm[lang].Programm}
							</a>
							&nbsp;
						</>
					)}
					{/*<>
						<a
							href={"./downloads/ostara_hygienekonzept.pdf"}
							className="button"
							style={{ lineHeight: "1.5em", height: "1.5em" }}
						>
							{crm[lang].Hygienekonzept}
						</a>
						&nbsp;
					</>*/}
					<a
						href="./downloads/lageplan.pdf"
						className="button"
						style={{ lineHeight: "1.5em", height: "1.5em" }}
					>
						{crm[lang].Lageplan}
					</a>
				</p>
			</div>
			<a href={`#${props.next}`} className="button style2 down anchored">
				Next
			</a>
		</section>
	);
};

const crm: any = {
	DE: {
		Anmeldung: "Anmeldung Ostara 2025",
		Text: (
			<p>
				Interessierte mit und ohne Mitgliedschaft im Eldaring können sich bereits jetzt anmelden, denn die
				Plätze sind begrenzt. Erwartungsgemäß nehmen über 160 Leute aus Deutschland und anderen Ländern teil.
				Das Blót wird in den frühen Morgenstunden abgehalten.
			</p>
		),
		Anmeldeformular: "Anmeldeformular",
		Preise: "Preise",
		AGB: "AGB",
		Programm: "Programm",
		Hygienekonzept: "Hygienekonzept",
		Lageplan: "Lageplan",
	},
	EN: {
		Anmeldung: "Registration Ostara 2025",
		Text: (
			<p>
				Registration is open to all, whether a member of Eldaring or not. Registration should be made now for
				this Ostara event, but hurry as spaces are limited. Over 150 people from Germany and other countries are
				expected to participate. The Blót will be held in the early hours of the morning.
			</p>
		),
		Anmeldeformular: "Registration (via email)",
		Preise: "Prices",
		AGB: "AGB",
		Programm: "Program",
		Hygienekonzept: "Hygiene regulations (de)",
		Lageplan: "Location plan",
	},
	NL: {
		Anmeldung: "Aanmelden voor Ostara 2025",
		Text: (
			<p>
				Geïnteresseerden met of zonder lidmaatschap van de Eldaring kunnen zich nu al voor Ostara aanmelden, het
				aantal plaatsen is echter beperkt. Naar verwachten nemen meer dan 150 mensen uit Duitsland en andere
				landen deel. Het blót wordt in de vroege ochtenduren gehouden.
			</p>
		),
		Anmeldeformular: "Aanmelden (via email)",
		Preise: "Prijzen",
		AGB: "AGB",
		Programm: "Programm",
		Hygienekonzept: "Hygiene regulations (de)",
		Lageplan: "Plattegrond",
	},
};

export default Anmeldung;
