import React from "react";
import { Route, Switch } from "react-router-dom";
import Anmeldefrist from "./Anmeldefrist";
import Anmeldung from "./Anmeldung";
import CRM from "./CRM";
import Eldaring from "./Eldaring";
import Footer from "./Footer";
import Header from "./Header";
import Impressionen from "./Impressionen";
import Impressum from "./Impressum";
import Intro from "./Intro";
import Kontakt from "./Kontakt";
import Veranstaltung from "./Veranstaltung";

const app = (language: string) => (
	<CRM.Provider value={{ language }}>
		<Header />
		<Anmeldefrist showDlg={false} />
		<Intro id="intro" next="one" />
		<Veranstaltung id="one" next="two" />
		<Anmeldung id="two" next="three" programToShow="" />
		<Eldaring id="three" next="four" />
		<Impressionen id="four" next="" />
		<Kontakt />
		<Impressum />
		<Footer />
	</CRM.Provider>
);

// programToShow={"Ostara Programm 2020.pdf"}

const App = () => {
	return (
		<>
			<Switch>
				<Route exact path="/">
					{app("DE")}
				</Route>
				<Route path="/en">{app("EN")}</Route>
				<Route path="/nl">{app("NL")}</Route>
			</Switch>
		</>
	);
};

export default App;
