import React, { useContext } from "react";

import CRM from "./CRM";
import { SectionProps } from "./SectionProps";

const Impressionen = (props: SectionProps) => {
    const lang = useContext(CRM).language;
    return <section id={props.id} className="main style3 primary">
        <div className="content container">
            <header>
                <h2>{crm[lang].Impressionen}</h2>
            </header>
            <div className="container 75% gallery">
                <div className="row 0% images">
                    <div className="6u 12u(mobile)">
                        <a href="images/0.jpg" className="image fit from-left">
                            <img src="images/thumbs/0.jpg" title="Fire" alt="" />
                        </a>
                    </div>
                    <div className="6u 12u(mobile)">
                        <a href="images/1.jpg" className="image fit from-right">
                            <img src="images/thumbs/1.jpg" title="Airchitecture" alt="" />
                        </a>
                    </div>
                </div>
                <div className="row 0% images">
                    <div className="6u 12u(mobile)">
                        <a href="images/2.jpg" className="image fit from-left">
                            <img src="images/thumbs/2.jpg" title="Castle" alt="" />
                        </a>
                    </div>
                    <div className="6u 12u(mobile)">
                        <a href="images/7.jpg" className="image fit from-right">
                            <img src="images/thumbs/7.jpg" title="Castle" alt="" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>;
};

const crm: any = {
    DE: {
        Impressionen: "Impressionen",
    },
    EN: {
        Impressionen: "Gallery",
    },
    NL: {
        Impressionen: "Impressies",
    }
};

export default Impressionen;
