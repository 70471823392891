import React, { useState } from "react";

import Modal from "./components/Modal";

const Anmeldefrist = (props: {showDlg: boolean}) => {
    const [show, setShow] = useState(true);
    return <Modal
        show={props.showDlg && show}
        onClose={() => setShow(false)}
        //title="Wichtig! Informationen zur Veranstaltung 2021."
        title="Wichtig! Ostara Sommerakademie 2021."
    >
        <p>Liebe Eldaringler (und Freunde des Eldaring),</p>

        <p>wie wäre es, wenn du nicht länger auf Ostara warten müsstest? Geniale Vorträge, Musik und Lesungen genießen kannst – und mit anderen Heiden sumbeln?</p>
        <p>Wir von der Ostara AG laden dich ein zur Ostara-Sommerakademie. In dieser besonderen Veranstaltungsreihe wollen wir mit euch lauschen, lachen, feiern und den Sommer genießen.</p>
        <p>Sieben spannende Events mit einem abschließenden Skaldenabend zur Herbst-Tag- und Nachtgleiche.</p>
        <p>Die Sommerakademie findet vom 15. August bis zum 26. September jeden Sonntag Abend statt. Wir treffen uns in der Kneipe am Kreuzweg, Zoom führt dich dorthin. Es warten immer zwei Vortragende auf euch, die in zwei Modulen à etwa 45 Minuten Lesungen, Workshops, Vorträge oder Musik für euch im Gepäck haben. Am 26. September steht dann der Skaldenabend auf dem Programm (Anmeldungen ausdrücklich erwünscht!)</p>
        <p>Das vollständige Programm und mehr Infos findest du bald genau hier. Soviel schon vorab: Es wird ein Rum-Tasting geben.</p>
        <p><strong>Wann?</strong> 15.8.-26.9.2021<br /><strong>Was?</strong> Lesungen, Musik, Vorträge und Workshops im Doppelpack<br /><strong>Anmeldung:</strong> Bitte per Mail an <a href="mailto:ostara@eldaring.de">ostara@eldaring.de</a><br /></p>
        <p><strong>Beitrag:</strong> Du wirst nach deiner Anmeldung von uns gebeten, 9 Euro in die Ostara-Kasse zu überweisen (Bankverbindung bekommst du von uns per Mail).</p>
        <p>Dieser Beitrag sichert dir die Teilnahme an so vielen Sommerakademie-Abenden, wie du willst: Einmal bezahlen, unbegrent Sommerakademie genießen!</p>
        <p><a href="./downloads/Ostara Programm Sommerakademie 2021.pdf" target="_blank">Das Programm der Sommerakademie</a> / <a href="./downloads/Datenschutzhinweise-Sommerakademie.pdf" target="_blank">Datenschutzhinweise für Teilnehmer</a></p>
    </Modal>;
};

export default Anmeldefrist;

/*  <p>
            Die Veranstaltung Ostara 2016 ist ausgebucht. Die Jugendburg Ludwigstein wünscht zwei Monate vor
            Veranstaltungsbeginn eine verlässliche Mitteilung der Zahlen, diese haben wir mit fast <em>170</em>
            Angemeldeten abgeliefert. Wer noch kommen mag, soll eine Einzelanfrage an
            <a href="mailto:hermann.ritter@homomagi.de">Hermann Ritter</a> stellen; versprechen kann man nichts,
            aber wir geben uns alle Mühe.
        </p>
        <p
            >Wir weisen darauf hin, dass wir vor Ort das Hausrecht ausüben und kein unangemeldeter Gast die
            Veranstaltung besuchen kann. Die Burg ist ein sehr offenes Gelände, anders kriegen wir
            „unerwünschte Besucher“ und die Versicherung nicht unter einen Hut.
        </p>
        <p>
            Wir freuen uns auf euch …
        </p>*/
