import React, { useContext, useState } from "react";
import Modal from "./components/Modal";
import CRM from "./CRM";

const Impressum = () => {
    const lang = useContext(CRM).language;
    const [impressum, setImpressum] = useState(false);
    const [dsgvo, setDSGVO] = useState(false);

    return <section id="impressum" className="main style3 secondary">
        <div className="content container">
            <span style={{margin: 8}}>
                <a className="button" style={{lineHeight: "1.5em", height: "1.5em"}} onClick={() => setImpressum(true)}>
                    {crm[lang].impressumTitle || crm.DE.impressumTitle}</a></span>
            <span style={{margin: 8}}>
                <a className="button" style={{lineHeight: "1.5em", height: "1.5em"}} onClick={() => setDSGVO(true)}>
                    Datenschutzerklärung</a></span>
        </div>
        <Modal show={impressum} title={crm[lang].impressumTitle} onClose={() => setImpressum(false)}>
            {crm[lang].impressum || crm.DE.impressum}
        </Modal>
        <Modal show={dsgvo} title="Datenschutzerkl&auml;rung" onClose={() => setDSGVO(false)}>
        <h2>Datenschutz</h2> <p>Die Betreiber dieser Seiten nehmen
            den Schutz Ihrer pers&ouml;nlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten
            vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser
            Datenschutzerkl&auml;rung.</p> <p>Die Nutzung unserer Website ist in der Regel ohne Angabe
            personenbezogener Daten m&ouml;glich. Soweit auf unseren Seiten personenbezogene Daten
            (beispielsweise Name, Anschrift oder E-Mail-Adressen) erhoben werden, erfolgt dies, soweit
            m&ouml;glich, stets auf freiwilliger Basis. Diese Daten werden ohne Ihre ausdr&uuml;ckliche Zustimmung
            nicht an Dritte weitergegeben.</p> <p>Wir weisen darauf hin, dass die Daten&uuml;bertragung im Internet
            (z.B. bei der Kommunikation per E-Mail) Sicherheitsl&uuml;cken aufweisen kann. Ein l&uuml;ckenloser
            Schutz der Daten vor dem Zugriff durch Dritte ist nicht m&ouml;glich.</p><p>&nbsp;</p>
            <h2>Cookies</h2> <p>Die Internetseiten verwenden teilweise so genannte Cookies. Cookies richten auf
            Ihrem Rechner keinen Schaden an und enthalten keine Viren. Cookies dienen dazu, unser Angebot
            nutzerfreundlicher, effektiver und sicherer zu machen. Cookies sind kleine Textdateien, die auf Ihrem
            Rechner abgelegt werden und die Ihr Browser speichert.</p> <p>Die meisten der von uns verwendeten
            Cookies sind so genannte „Session-Cookies“. Sie werden nach Ende Ihres Besuchs automatisch
            gel&ouml;scht. Andere Cookies bleiben auf Ihrem Endger&auml;t gespeichert, bis Sie diese l&ouml;schen.
            Diese Cookies erm&ouml;glichen es uns, Ihren Browser beim n&auml;chsten Besuch
            wiederzuerkennen.</p> <p>Sie k&ouml;nnen Ihren Browser so einstellen, dass Sie &uuml;ber das Setzen
            von Cookies informiert werden und Cookies nur im Einzelfall erlauben, die Annahme von Cookies f&uuml;r
            bestimmte F&auml;lle oder generell ausschlie&szlig;en sowie das automatische L&ouml;schen der
            Cookies beim Schlie&szlig;en des Browser aktivieren. Bei der Deaktivierung von Cookies kann die
            Funktionalit&auml;t dieser Website eingeschr&auml;nkt sein.</p><p>&nbsp;</p> <h2>Server-Log-
            Files</h2> <p>Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten
            Server-Log Files, die Ihr Browser automatisch an uns &uuml;bermittelt. Dies sind:</p> <ul>
            <li>Browsertyp und Browserversion</li> <li>verwendetes Betriebssystem</li> <li>Referrer URL</li>
            <li>Hostname des zugreifenden Rechners</li> <li>Uhrzeit der Serveranfrage</li> </ul> <p>Diese Daten
            sind nicht bestimmten Personen zuordenbar. Eine Zusammenf&uuml;hrung dieser Daten mit anderen
            Datenquellen wird nicht vorgenommen. Wir behalten uns vor, diese Daten nachtr&auml;glich zu
            pr&uuml;fen, wenn uns konkrete Anhaltspunkte f&uuml;r eine rechtswidrige Nutzung bekannt
            werden.</p><p>&nbsp;</p> <h2>Kontaktformular</h2> <p>Wenn Sie uns per Kontaktformular Anfragen
            zukommen lassen, werden Ihre Angaben aus dem Anfrageformular inklusive der von Ihnen dort
            angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und f&uuml;r den Fall von Anschlussfragen
            bei uns gespeichert. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.</p><p>&nbsp;</p>
            <h2>SSL-Verschl&uuml;sselung</h2> <p>Diese Seite nutzt aus Gr&uuml;nden der Sicherheit und zum
            Schutz der &Uuml;bertragung vertraulicher Inhalte, wie zum Beispiel der Anfragen, die Sie an uns als
            Seitenbetreiber senden, eine SSL-Verschl&uuml;sselung. Eine verschl&uuml;sselte Verbindung erkennen
            Sie daran, dass die Adresszeile des Browsers von &quot;http://&quot; auf &quot;https://&quot; wechselt
            und an dem Schloss-Symbol in Ihrer Browserzeile.</p> <p>Wenn die SSL Verschl&uuml;sselung aktiviert
            ist, k&ouml;nnen die Daten, die Sie an uns &uuml;bermitteln, nicht von Dritten mitgelesen
            werden.</p><p>&nbsp;</p> <h2>Recht auf Auskunft, L&ouml;schung, Sperrung</h2> <p>Sie haben
            jederzeit das Recht auf unentgeltliche Auskunft &uuml;ber Ihre gespeicherten personenbezogenen Daten,
            deren Herkunft und Empf&auml;nger und den Zweck der Datenverarbeitung sowie ein Recht auf
            Berichtigung, Sperrung oder L&ouml;schung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema
            personenbezogene Daten k&ouml;nnen Sie sich jederzeit unter der im Impressum angegebenen Adresse
            an uns wenden.</p><p>&nbsp;</p> <p>Quelle:
            <a href="https://www.e-recht24.de">https://www.e-recht24.de</a></p>
        </Modal>
    </section>;
};

const crm: any = {
    DE: {
        impressumTitle: "Impressum",
        impressum: <>
        <h2>Angaben gem&auml;&szlig; &sect; 5 TMG:</h2>
            <p>Lokis Lapsus Gbr<br />
            Clarenstraße 11<br /> D-32052 Herford </p> <h2>Vertreten durch:</h2> <p>Vertreten durch die
            Gesellschafter Andreas Cushion und Hermann Ritter.</p> <h2>Kontakt:</h2> <p>Telefon:
            0177/8429780<br /> Telefax: -<br /> E-Mail: hermann.ritter@homomagi.de</p> <h2>Umsatzsteuer:</h2>
            <p>Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect;27 a Umsatzsteuergesetz:<br />
            324/5802/2864</p> <h2>Verantwortlich f&uuml;r den Inhalt nach &sect; 55 Abs. 2 RStV:</h2>
            <p>Hermann Ritter<br /> Clarenstraße 11<br /> 32052 Herford</p> <h2>Streitschlichtung</h2>
            <p>Die Europ&auml;ische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit:
            <a href="https://ec.europa.eu/consumers/odr" target="_blank">https://ec.europa.eu/consumers/odr</a><br />
            Unsere E-Mail-Adresse finden Sie oben im Impressum.</p> <p>Wir sind nicht bereit oder verpflichtet, an
            Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p> <h2>Haftung
            f&uuml;r Inhalte</h2> <p>Als Diensteanbieter sind wir gem&auml;&szlig; &sect; 7 Abs.1 TMG f&uuml;r
            eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach &sect;&sect; 8 bis
            10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, &uuml;bermittelte oder gespeicherte fremde
            Informationen zu &uuml;berwachen oder nach Umst&auml;nden zu forschen, die auf eine rechtswidrige
            T&auml;tigkeit hinweisen.</p> <p>Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
            Informationen nach den allgemeinen Gesetzen bleiben hiervon unber&uuml;hrt. Eine diesbez&uuml;gliche
            Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung m&ouml;glich.
            Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend
            entfernen.</p> <h2>Haftung f&uuml;r Links</h2> <p>Unser Angebot enth&auml;lt Links zu externen
            Websites Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb k&ouml;nnen wir f&uuml;r diese
            fremden Inhalte auch keine Gew&auml;hr &uuml;bernehmen. F&uuml;r die Inhalte der verlinkten Seiten ist
            stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum
            Zeitpunkt der Verlinkung auf m&ouml;gliche Rechtsverst&ouml;&szlig;e &uuml;berpr&uuml;ft.
            Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.</p> <p>Eine permanente
            inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung
            nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend
            entfernen.</p> <h2>Urheberrecht</h2> <p>Die durch die Seitenbetreiber erstellten Inhalte und Werke auf
            diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielf&auml;ltigung, Bearbeitung,
            Verbreitung und jede Art der Verwertung au&szlig;erhalb der Grenzen des Urheberrechtes bed&uuml;rfen
            der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite
            sind nur f&uuml;r den privaten, nicht kommerziellen Gebrauch gestattet.</p> <p>Soweit die Inhalte auf
            dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere
            werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung
            aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von
            Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.</p>
            <p>Quelle: <a href="https://www.e-recht24.de">eRecht24</a></p>
    </>
    },
    EN: {
        impressumTitle: "Imprint and Privacy Policy",
        impressum: <>
        <h2>Information according th § 5 TMG (German Telemedia Act):</h2>
        Lokis Lapsus Gbr
        Clarenstraße 11
        D-32052 Herford
        <h2>Represented by:</h2>
        the shareholders Andreas Cushion and Hermann Ritter.
        <h2>Contact:</h2>
        Telephone: Germany: 0177 8429780 – International: +49 177 8429780
        Telefax: -
        E-Mail: hermann.ritter@homomagi.de
        <h2>Value added tax:</h2>
        Value added tax ID-number according to §27a of the German Added Tax Act:
        324/5802/2864
        <h2>Responsible for the content according § 55 Abs. 2 RStV:</h2>
        Hermann Ritter
        Clarenstraße 11
        32052 Herford
        <h2>Dispute arbitration</h2>
        <p>The European Commission provides a platform for online dispute resolution (OS):
        https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=EN</p>
        <p>Our e-mail address can be found in the imprint section above.</p>
        <p>We are not willing or obliged to participate in dispute resolution proceedings at
        a consumer arbitration board.</p>

        <h2>Liability for content</h2>
        <p>As a service provider according to § 7 Abs.1 TMG we are responsible for our own contents of these pages in
            accordance with the respected general laws. According to §§ 8 to 10 TMG, however, we as a service provider
            are not obligated to monitor transmitted or stored third-party information or to investigate circumstances
            that indicate an illegal activity.</p>
        <p>The obligation to remove or block the use of information under general law remains unaffected. A liability in
            this regard, however, is only possible from the date of knowledge of a specific infringement. Upon
            notification
            of appropriate violations, we will remove such contents immediately.</p>
        <h2>Liability for links</h2>
        <p>Our information contains links to external websites of third parties whose contents we have no control over.
            Therefore, we cannot accept liability for these external parties contents. The respective provider or
            operator of these pages is always responsible for the contents of the linked pages. The linked pages were
            checked for possible legal violations at the time of linking. No illegal content was recognizable at the
            time of linking.</p><p>
        However, without concrete evidence of an infringement a permanent content control of the linked pages is not
        reasonable. Upon notification of violations, we will remove such links immediately.</p>
        <h2>Copyright</h2>
        <p>The content and works on these pages created by the site operators are subject to German copyright law.
            Any reproduction, processing, distribution and any kind of exploitation outside the limits of copyright
            law requires the written permission of the respective author or creator. The use of downloads and copies
            of this site is allowed for private, non-commercial use only.</p>
        <p>Concerning contents on this side that were not created by the operators, the copyrights of third parties
            are considered. In particular, contents from third parties are marked as such. If you become aware of a
            copyright infringement, we ask that you please inform us at the earliest convenience. Upon notification
            of violations, we will remove such content immediately.</p>

        <p>Original German Source: eRecht24</p>

        <em>This translated text is our service. This text is a translation of the corresponding German text.
            It is solely meant as our service to you. The corresponding German text in the German section of this
            site is authoritative and decisive because this site is located in Germany and falls under German Law.</em>

        </>
    },
    NL: {
        impressumTitle: "Colofon en privacy-verklaring",
        impressum: <>
        <p>Omdat zowel het evenement „Ostara“ als ook deze webpresentatie onder de Duitse wetgeving vallen, is de
        Duitse regelgeving voor onderstaande informatie maatgevend.</p>
        <p>Gegevens overeenkomstig § 5 TMG: (Duitse telemediawet)<br />
        Lokis Lapsus Gbr<br />
        Clarenstraße 11<br />
        D-32052 Herford<br />
        Gerepresenteerd door:<br />
        De vennoten Andreas Cushion en Hermann Ritter.</p>
        <h2>Contact:</h2>
        Telefoon: Duitsland: 0177 8429780 – Internationaal: +49 177 8429780<br />
        Telefax: -<br />
        E-Mail: hermann.ritter@homomagi.de<br />
        <h2>BTW (Umsatzsteuer):</h2>
        Umsatzsteuer-Identificatie nummer overeenkomstig §27 BTW-wetgving:<br />
        324/5802/2864<br />
        Verantwoordelijk voor de inhoud overeenkomstig § 55 Abs. 2 RStV:<br />
        Hermann Ritter<br />
        Clarenstraße 11<br />
        32052 Herford<br />
        <h2>geschillenregeling</h2>
        De Europese Commissie stelt een platform bereid voor een zur online geschillenregeling:
        https://ec.europa.eu/consumers/odr<br />
        Ons e-mail adres vindt u hierboven onder ‚Contact‘.
        We zijn niet bereid of verplicht om deel te nemen aan geschillenbeslechtingsprocedures
        voor een arbitragecommissie voor consumenten.<br />
        <h2>Aansprakelijkheid voor de inhoud</h2>
        <p>Als dienstverlener zijn wij volgens § 7 1 TMG verantwoordelijk voor eigen inhouden op deze
        pagina's op basis van de algemene regelgeving. Volgens §§ 8 tot 10 TMG zijn wij als
        dienstverlener echter niet verplicht om doorgegeven of opgeslagen informatie van derden
        te controleren of om omstandigheden te onderzoeken die wijzen op een illegale activiteit.</p>
        <p>Verplichtingen tot het verwijderen of blokkeren van het gebruik van informatie op grond van
            de algemene wetgeving blijven onaangetast. Echter, een relevante verplichting is alleen mogelijk
            vanaf de datum van kennisname van een specifieke inbreuk. Na kennisgeving van desbetreffende juridische
            schendingen zullen we deze inhoud onmiddellijk verwijderen.</p>
        <h2>Aansprakelijkheid voor links</h2>
        <p>Ons aanbod bevat links naar externe websites van derden waarvan wij de inhoud niet kunnen beïnvloeden.
            Daarom kunnen we geen enkele verantwoordelijkheid voor de inhoud ervan overnemen. De aanbieder of
            exploitant van dergelijke sites is altijd verantwoordelijk voor de inhoud van de gelinkte pagina's.
            De gekoppelde sites werden op het moment van verlinken gecontroleerd op mogelijke wettelijke overtredingen.
            Illegale inhouden werden op het moment van linken niet gevonden.</p>
        <p>Zonder concrete aanwijzingen op juridische overtredingen is een permanente controle van de gelinkte pagina's
            redelĳkerwĳs van ons niet te verwachten. Na kennisgeving van overtredingen, zullen wij onmiddellijk
            dergelijke links te verwijderen.</p>
        <h2>Copyright</h2>
        <p>De door de site-exploitanten gemaakte inhouden en ontwerpen op deze pagina's zijn onderworpen aan het Duitse
            auteursrecht. De reproductie, verwerking, distributie en elke vorm van exploitatie buiten de grenzen van het
            auteursrecht vereist de schriftelijke toestemming van de betreffende auteur, kunstenaar of maker. Downloads
            en kopieën zijn alleen toegestaan voor privé, niet-commercieel gebruik.</p>
        <p>Voor zover de inhoud niet gemaakt werd door de website exploitant, worden rechten van de auteursrechten van
            derden in acht genomen.Dergelijke inhouden van derden zijn als zodanig aangegeven. Wanneer u een schending
            van het auteursrecht bemerkt, vragen wij om een wenk uwerzijds. Na kennisgeving van overtredingen, zullen
            wij de inhoud onmiddellijk verwijderen.</p>
        <h5>Bron: <a href="https://www.e-recht24.de">eRecht24</a></h5>
        </>
    }
};

export default Impressum;
