import React, { useContext, useState } from "react";
import CRM from "./CRM";

const Kontakt = () => {
    const [advisory, setAdvisory] = useState(false);
    const lang = useContext(CRM).language;
    return <section id="contact" className="main style3 secondary">
        <div className="content container">
            <header>
                <h2>{crm[lang].Kontakt}</h2>
                {crm[lang].text}
            </header>
            <div className="box container 75%">
                <form method="post" action="postmessage.php">
                    <div className="row 50%">
                        <div className="6u 12u(mobile)">
                        <input type="text" name="name" placeholder={crm[lang].Name} /></div>
                        <div className="6u 12u(mobile)">
                        <input type="email" name="email" placeholder={crm[lang].Email} /></div>
                    </div>
                    <div className="row 50%">
                        <div className="12u">
                        <textarea name="message" placeholder={crm[lang].Nachricht} rows={6} />
                        </div>
                    </div>
                    {advisory && <div className="row">
                        <div className="12u">
                            <ul className="actions">
                                <li><input type="submit" value={crm[lang].Senden} /></li>
                            </ul>
                        </div>
                    </div>}
                    <div className="row">
                    <div className="12u text-left">
                        <input
                            type="checkbox"
                            checked={advisory}
                            onChange={() => setAdvisory(!advisory)}
                            style={{marginRight: 5}}
                        />
                        {crm[lang].Advisory}
                    </div>
                    </div>
                </form>
            </div>
        </div>
    </section>;
};

const crm: any = {
    DE: {
        Kontakt: "Kontakt",
        Text: <p>Nutze das Formular um mit uns in Kontakt zu treten.</p>,
        Advisory: <>Mit dem Absenden des Formulars erklären Sie sich mit der Speicherung und Verarbeitung
        Ihrer Daten zum Zwecke der Kontaktaufnahme einverstanden.</>,
        Name: "Name",
        Email: "Email",
        Nachricht: "Nachricht",
        Senden: "Senden"
    },
    EN: {
        Kontakt: "Contact",
        Text: <p>Use this form to contact us or to register for the event.</p>,
        Advisory: <>By submitting this form you declare consent with storage and usage of
            the above data for contacting purposes.</>,
        Name: "Name",
        Email: "Email",
        Nachricht: "Message",
        Senden: "Send"
    },
    NL: {
        Kontakt: "Contact",
        Text: <p>Gebruik dit formulier om met ons in contact te komen of om je op te geven.</p>,
        Advisory: <>By submitting this form you declare consent with storage and usage of the above
            data for contacting purposes.</>,
        Name: "Naam",
        Email: "Email",
        Nachricht: "Bericht",
        Senden: "Verzenden"
    }
};

export default Kontakt;
