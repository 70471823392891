import React from "react";

const Footer = () => <footer id="footer">
    <ul className="actions">
        <li><a href="https://www.facebook.com/groups/169923973176567/" className="icon fa-facebook">
            <span className="label">Ostara auf Facebook</span>
        </a></li>
    </ul>
    <ul className="menu">
        <li>&copy; Lokis Lapsus Gbr</li><li>Design: H. Belitz und<a href="http://html5up.net">HTML5 UP</a></li>
    </ul>
</footer>;

export default Footer;
