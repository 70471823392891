import React, { useContext } from "react";

import CRM from "./CRM";
import { SectionProps } from "./SectionProps";

const Eldaring = (props: SectionProps) => {
    const lang = useContext(CRM).language;
    return <section id={props.id} className="main style2 right dark fullscreen">
        <div className="content box style2">
            <header>
                <h2>Eldaring</h2>
            </header>
            {crm[lang].content}
        </div>
        <a href={`#${props.next}`} className="button style2 down dark anchored">Next</a>
    </section>;
};

const crm: any = {
    DE: {
        content: <><p>Auf der Mitgliederversammlung 2015 des Eldaring e.V. konnte eine Kooperation zwischen
            den Ostara Veranstaltern und dem Eldaring e.V. verhandelt werden.</p>
        <p>Ostara wird dementsprechend in Kooperation mit dem Eldaring e.V. durchgeführt.</p></>,
    },
    EN: {
        content: <><p>A collaboration between the Ostara organizers and the Eldaring eV was created in 2015
        at the Annual General Meeting of Eldaring e.V.</p><p>
        Ostara is therefore carried out in collaboration with Eldaring eV.</p></>,
    },
    NL: {
        content: <><p>Tijdens de jaarvergadering van de Eldaring e.V. in 2015 is een samenwerkingsverband tussen de
        organisatie van het Ostara-evenement en de Eldaring e.V. tot stand gekomen.</p><p>
        Het Ostara-evenement wordt dienovereenkomstig in samenwerking met de Eldaring e.V. georganiseerd.</p></>,
    }
};

export default Eldaring;
