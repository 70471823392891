import React, { useContext, useState } from "react";
import HorizontalTimeline from "react-horizontal-timeline";
import Modal from "./components/Modal";
import CRM from "./CRM";

const VALUES = [
	"2001",
	"2003",
	"2004",
	"2005",
	"2006",
	"2007",
	"2008",
	"2009",
	"2010",
	"2011",
	"2012",
	"2013",
	"2014",
	"2015",
	"2016",
	"2017",
	"2018",
	"2019",
	"2020",
	"2021",
	"2022",
	"2023",
	"2024",
];

// tslint:disable:max-line-length

const chronic: any = {
	2001: {
		DE: (
			<>
				<h3>Am Anfang war eine Idee</h3>
				<p>
					Ende der 90er/Anfang 2000 durchlebte die damals noch recht junge heidnische Szene in Deutschland
					eine sehr turbulente Zeit. Sie war untereinander geprägt durch Positionierungs-, Definierungs- und
					Abgrenzungskämpfe der einzelnen Gruppen und Vereine gegenüber rechtspolitischen Einflüssen. Nennen
					wollen wir hier die für uns relevanten Gruppen den Rabenclan, Nornirs Aett, Eldaring und den Odinic
					Rite Deutschland (ab 2006 VfgH).
				</p>
				<p>
					Als damalige Mitglieder der Nornirs Aett war unsere Positionierung in dieser Diskussion klar.
					Christian im Besonderen waren einige Eldaringler persönlich bekannt. Und so taten wir uns in der
					überwiegend im Internet geführten hitzigen Diskussion schwer und diese schien sich immer mehr von
					einer sachlichen in eine persönliche Ebene zu verschieben.
				</p>
			</>
		),
		NL: (
			<>
				<h3>In het begin was er een idee</h3>
				<p>
					Aan het eind van de jaren negentig / begin 2000 beleefde de toen nog vrij jonge heidense scène in
					Duitsland een zeer turbulente tijd. Deze werd gekarakteriseerd door positionerings-, afbakenings- en
					afgrenzingsgevechten van de individuele groepen en verenigingen tegen invloeden vanuit de
					rechtsextreme politiek. We willen hier de groepen noemen die voor ons relevant zijn: de Rabenclan,
					Nornirs Aett, Eldaring en de Odinic Rite Duitsland (vanaf 2006 VfgH).
				</p>
				<p>
					Als voormalige leden van de 'Nornirs Aett' was onze positionering in deze discussie duidelijk.
					Christian (Kordas) kende sommige Eldaring-leden persoonlijk. Vanwege enige verschillen hadden we het
					moeilijk in de verhitte discussie, die vooral op internet werd gevoerd, en deze leek zich steeds
					meer te verschuiven van een zakelijk naar een persoonlijk niveau.
				</p>
			</>
		),
		EN: (
			<>
				<h3>The beginning was an idea</h3>
				<p>
					At the end of the 1990’s and the beginning of 2000, the then still quite young pagan scene in
					Germany experienced a very turbulent time. It was characterized by positioning, defining and
					demarcation struggles of the various groups and organizations against right wing political
					influences.
				</p>
				<p>
					We would like to mention here the (pagan) groups relevant to us, which are: the Rabenclan, Nornirs
					Aett, Eldaring and the Odinic Rite Germany (from 2006 known as VfgH).
				</p>
				<p>
					Our position in this discussion was clear to the members of Nornirs Aett at that time. Christian
					(Kordas) in particular knew some Eldaring members personally and so we had a tough time with heated
					discussions, which were mainly held via the internet, that seemed to increasingly shift more from a
					factual to a personal level.
				</p>
			</>
		),
	},
	2003: {
		DE: (
			<>
				<p>
					Also beschlossen wir, gemeinsam mit Lars Rode den persönlichen Kontakt zum Eldaring zu suchen und
					meldeten uns 2002 für das Eldaring Treffen auf der Siegburg an. Wir wagten uns in die Höhle des
					Löwen. Misstrauisch wurden wir Nornirs Aett-Mitglieder („Aettlinge“) beäugt und standen recht
					alleine im Burghof. Wir auf der einen, die Eldaringler auf der anderen Seite. Isa Jauss kam auf uns
					zu und warf den Ihren Feigheit vor ;) Es löste sich das Misstrauen und wir hatten einen
					unbeschreiblichen tollen Abend. Wir schafften es, dass katholische Messdiener und deren Betreuer
					tief in die Met Hörner schauten und dann auf die alten Götter tranken und sangen ;).
				</p>
				<p>
					Uns wurde klar, dass nur persönliche Begegnungen die Anfeindungen befrieden konnten. Wir beide
					hatten die Idee, Begegnungen vereinsunabhängig auf einem neutralen Boden zu ermöglichen. Lars
					konnten wir ebenfalls dafür begeistern.
				</p>
				<p>Ostara war geboren.</p>
			</>
		),
		NL: (
			<>
				<p>
					Daarom besloten we samen met Lars Rode persoonlijk contact te zoeken met de Eldaring en in 2002
					meldden we ons aan voor deelname aan de meerdaagse Eldaring-bijeenkomst op de Siegburg. We waagden
					ons in de leeuwenkuil. Wij als leden van Nornirs Aett (Aettlingen) werden argwanend bekeken en
					stonden vrij alleen op de binnenplaats van de burcht. Wij aan de ene kant, de Eldaringler aan de
					andere kant. Isa Jauss kwam naar ons toe en beschuldigde haar mede-Eldaringler van lafheid ;). Het
					loste het wantrouwen op en we hadden een onbeschrijfelijk geweldige avond. We slaagden erin dat ook
					in de burcht aanwezige katholieke misdienaars en hun verzorgers diep in de met mede gevulde hoorns
					keken, en dan dronken en zongen ter ere van de oude goden ;).
				</p>
				<p>
					We realiseerden ons dat alleen persoonlijke ontmoetingen de vijandigheid in vrede omgezet kon
					worden. Beide groeperingen voelden veel voor het idee om ontmoetingen onafhankelijk van de
					desbetreffende verenigingen op een neutraal terrein te organiseren. We hebben Lars er ook voor
					kunnen inspireren.
				</p>
				<p>En zo werd het Ostara festival geboren.</p>
			</>
		),
		EN: (
			<>
				<p>
					Therefor we decided, in cooperation with Lars Rode, to seek personal contact with the Eldaring group
					and in 2002 we created the Eldaring moot in Siegburg castle. We now stepped into the lion's den. We,
					being Nornir's Aett members ("Aettlinge") were looked at with suspicion and mainly stood alone in
					the courtyard separately from the others. Us on one side, the Eldaringler on the other when Isa
					Jauss came over to us and admitted they were afraid ;-) It dissolved the distrust and we had an
					incredible evening thereafter. We even managed to have a great time with Catholic ministers and
					their caretakers who were also present at the castle and had a drop too much of mead with us in our
					horns and drank whilst they sang songs of the old gods. ;-)
				</p>
				<p>
					We realized that only personal encounters could pacify the hostilities. We both had the idea of
					facilitating an encounter on neutral grounds and independent of other groups or associations. We
					were even able to inspire Lars to come along.
				</p>
				<p>Thus, Ostara was born.</p>
			</>
		),
	},
	2004: {
		DE: (
			<>
				<p>
					<em>26. - 28.03 auf Burg Sternberg,</em>
					<br />
					veranstaltet von Peter Broedlau, Andreas Cushion, Christian Kordas und Olaf Schneider
				</p>
				<p>
					Ermutig von den Teilnehmern planten wir dann mit den beiden Eldaring Mitgliedern Peter Broedlau und
					Olaf Schneider das nächste Fest auszurichten. Interne Rabenclan-Querelen hatten viele
					Rabenclan-Mitglieder sich zurückziehen lassen.{" "}
				</p>
			</>
		),
		NL: (
			<>
				<p>
					<em>26. - 28.03 op Burg Sternberg,</em>
					<br /> georganiseerd door Peter Broedlau, Andreas Cushion, Christian Kordas en Olaf Schneider
				</p>
				<p>
					Aangemoedigd door de deelnemers, waren we van plan om het volgende festival te organiseren samen met
					de twee Eldaring-leden Peter Broedlau en Olaf Schneider. Door interne Rabenclan ruzies hadden
					clanleden zich daarvan teruggetrokken.
				</p>
			</>
		),
		EN: (
			<>
				<p>
					<em>26 - 28, 03 at Burg Sternberg,</em>
					<br /> organised by Peter Broedlau, Andreas Cushion, Christian Kordas, and Olaf Schneider
				</p>
				<p>
					Encouraged by the participants, together with the two Eldaring members Peter Broedlau and Olaf
					Schneider we then planned to host the next moot. Internal quarrels of the Rabenclan had caused many
					of their members to withdraw from the Ostara initiative.
				</p>
			</>
		),
	},
	2005: {
		DE: (
			<>
				<p>
					<em>25. - 27.03 auf der Tonenburg,</em>
					<br />
					veranstaltet von Peter Broedlau, Andreas Cushion und Christian Kordas
				</p>
				<p>
					So hatten wir beispielsweise den uns geschätzten Hermann Ritter stetig bekniet, doch einfach mal als
					Gast auf unserer jungen und entspannten Veranstaltung zu erscheinen. 2005 hatten wir es geschafft
					und er stand abends plötzlich in der Burg ;). Diesen Geist des Willkommens haben wir uns bis heute
					erhalten.
				</p>
				<p>
					<a href="/downloads/archiv/Ostara Programm 2005.pdf" style={{ color: "#336" }}>
						<em>Programm herunterladen</em>
					</a>
				</p>
			</>
		),
		NL: (
			<>
				<p>
					<em>25. - 27.03 op de Tonenburg,</em>
					<br />
					georganiseerd door Peter Broedlau, Andreas Cushion en Christian Kordas
				</p>
				<p>
					{" "}
					We hadden herhaaldelijk de door ons gerespecteerde Hermann Ritter gevraagd mee te doen, maar
					nodigden hem eerst uit om gewoon om als gast te verschijnen tijdens ons jonge en ontspannen
					evenement. In 2005 hadden was het ons gelukt; hij verscheen plotseling 's avonds in de burcht. ;).
					We hebben deze aard van hartelijke verwelkoming tot op de dag van vandaag kunnen behouden.
				</p>
			</>
		),
		EN: (
			<>
				<p>
					<em>25 - 27, 03 at the Tonenburg,</em>
					<br />
					organised by Peter Broedlau, Andreas Cushion, and Christian Kordas
				</p>
				<p>
					For example, we tried to convince the esteemed Hermann Ritter who at first, simply attended as a
					guest at our young and relaxed event to come. In 2005 we had success as he suddenly appeared that
					evening at the castle ;-). We have received this spirit of welcoming to this very day.
				</p>
			</>
		),
	},
	2006: {
		DE: (
			<>
				<p>
					<em>24. - 26.03 auf Burg Sternberg,</em>
					<br />
					veranstaltet von Peter Broedlau, Andreas Cushion und Christian Kordas
				</p>
				<p>
					<a href="/downloads/archiv/Ostara Programm 2006.jpg" style={{ color: "#336" }}>
						<em>Programm herunterladen</em>
					</a>
				</p>
			</>
		),
		NL: (
			<>
				<p>
					<em>24. - 26.03 op Burg Sternberg,</em>
					<br /> georganiseerd door Peter Broedlau, Andreas Cushion en Christian Kordas
				</p>
			</>
		),
		EN: (
			<>
				<p>
					<em>24 - 26, 03 at Burg Sternberg,</em>
					<br /> organised by Peter Broedlau, Andreas Cushion, and Christian Kordas
				</p>
			</>
		),
	},
	2007: {
		DE: (
			<>
				<p>
					<em>23. - 25.03 auf der Tonenburg,</em>
					<br />
					veranstaltet von Andreas Cushion und Christian Kordas
				</p>
				<p>
					In den folgenden Jahren gewann der Küchenchef der Burg Sternberg immer mehr geschäftsführenden
					Einfluss und er teilte uns unter anderem auch seine Abneigung über das von uns gewünschte Essen mit
					(er hätte gerne ein kostspieliges Festmahl mit mehreren Gängen aufgefahren, doch unser Bestreben war
					es die Gesamtkosten für die Treffen relativ gering zu halten). Da die Veranstaltung auch stetig
					wuchs und wir die Kapazitäten der Burg erreicht hatten, fiel uns der Abschied von der Burg Sternberg
					leicht.
				</p>
				<p>
					So wechselten wir 2007 den Veranstaltungsort und unsere Wahl fiel auf die Tonenburg. Bevor wir
					jedoch die Zusage bekamen, mussten wir uns den bohrenden Fragen der damaligen Burgherrin Frau Pirone
					stellen. Sie gab sich bezüglich der germanischen Mythologie recht unwissend und fühlte uns auf den
					Zahn, ob wir doch keine verkappten Nazis seien – um uns im Nachhinein zu verstehen zu geben, genau
					über die germanische Mythologie Bescheid zu wissen.
				</p>
				<p>
					<a href="/downloads/archiv/Ostara Programm 2007.pdf" style={{ color: "#336" }}>
						<em>Programm herunterladen</em>
					</a>
				</p>
			</>
		),
		NL: (
			<>
				<p>
					<em>23. - 25.03 op de Tonenburg,</em>
					<br /> georganiseerd door Andreas Cushion en Christian Kordas
				</p>
				<p>
					In de volgende jaren kreeg de chefkok van het kasteel van Sternberg steeds meer invloed op het
					beheer en de leiding van de burcht. Daarbij gaf hij ons onder meer zijn afkeer te kennen over het
					door gewenste eten (hij zou veel liever een kostbaar feestmaal met diverse gangen hebben opgedist,
					maar ons doel was om de totale kosten van de bijeenkomst relatief laag te houden). Aangezien het
					aantal deelnemers aan het evenement ook gestaag groeide en we de capaciteitsgrenzen van het kasteel
					hadden bereikt, viel ons het afscheid van het kasteel Sternberg gemakkelijk.
				</p>
				<p>
					Dus veranderden we in 2007 van locatie en onze keuze viel op de Tonenburg (Tonenburcht). Voordat we
					de toeslag kregen, moesten we echter de lastige vragen van de voormalige burchtvrouwe, mevrouw
					Pirone, onder ogen zien. Ze deed zich met betrekking tot de Germaanse mythologie heel onwetend voor
					en voelde ons flink aan de tand, om te weten of we geen nazi's waren die zich in een dekmantel
					gehuld hadden. Achteraf maakte ze ons duidelijk wel heel goed op de hoogte te zijn van de Germaanse
					mythologie.
				</p>
			</>
		),
		EN: (
			<>
				<p>
					<em>23 - 25, 03 at the Tonenburg,</em>
					<br /> organised by Andreas Cushion and Christian Kordas
				</p>
				<p>
					In the years that followed, the head chief of Sternberg Castle gained more and more executive
					influence on the activities there and among other things informed us about his aversion to the food
					we preferred (he would have liked us to order a sumptuous multi-course feast, but our aim was to
					keep the total costs of the meetings relatively low). As the membership of the event steadily grew
					and the castle's capacity limits were reached, it became rather easy for us to say farewell to
					Sternberg Castle.
				</p>
				<p>
					Thus, in 2007 we changed location and chose Tonenburg Castle. However, before we got the
					confirmation, we had to face the nagging question of Mrs. Pirone, the former leader of the castle.
					She pretended to be quite ignorant about Germanic mythology and probed us to confirm we were not
					closet Nazis but ultimately made it clear that she knew exactly what Germanic mythology was about.
				</p>
			</>
		),
	},
	2008: {
		DE: (
			<>
				<p>
					<em>28. - 30.03 auf der Tonenburg,</em>
					<br />
					veranstaltet von Peter Broedlau, Andreas Cushion und Christian Kordas
				</p>
				<p>
					<a href="/downloads/archiv/Ostara Programm 2008.pdf" style={{ color: "#336" }}>
						<em>Programm herunterladen</em>
					</a>
				</p>
			</>
		),
		NL: (
			<>
				<p>
					<em>28. - 30.03 op de Tonenburg,</em>
					<br /> georganiseerd door Peter Broedlau, Andreas Cushion en Christian Kordas
				</p>
			</>
		),
		EN: (
			<>
				<p>
					<em>28 - 30, 03 at the Tonenburg,</em>
					<br /> organised by Peter Broedlau, Andreas Cushion, and Christian Kordas
				</p>
			</>
		),
	},
	2009: {
		DE: (
			<>
				<p>
					<em>19. - 22.03 im Bessunger Forst,</em>
					<br />
					veranstaltet von Hermann Ritter, in Kooperation mit dem Eldaring eV
				</p>
				<p>
					2009 nahmen wir eine Auszeit und Hermann Ritter richtete Ostara für den Eldaring im Bessunger Forst
					aus. Wir standen ihm helfend vor Ort bei, um im darauf folgenden Jahr wieder in der Organisation
					mitzuwirken.
				</p>
			</>
		),
		NL: (
			<>
				<p>
					<em>19. - 22.03 in Bessunger Forst,</em>
					<br /> georganiseerd door Hermann Ritter, in samenwerking met de Eldaring eV
				</p>
				<p>
					In 2009 namen wij een pauze en toen organiseerde Hermann Ritter het Ostara festival voor de Eldaring
					in de "Bessunger Forst". We hielpen hem ter plaatse, en namen ook weer deel aan de organisatie het
					volgende jaar.
				</p>
			</>
		),
		EN: (
			<>
				<p>
					<em>19 - 22, 03 at Bessunger Forst,</em>
					<br /> organised by Hermann Ritter, in cooperation with Eldaring eV
				</p>
				<p>
					In 2009 we took a break and Hermann Ritter organized the Ostara festival on behalf of the Eldaring
					in the Bessunger Forest (near the city of Darmstadt). We supported him on the spot and joined again
					to help organize the festival for the next year.
				</p>
			</>
		),
	},
	2010: {
		DE: (
			<>
				<p>
					<em>18. - 21.03 im Bessunger Forst,</em>
					<br />
					veranstaltet von Andreas Cushion, Christian Kordas und Hermann Ritter, in Kooperation mit dem
					Eldaring eV
				</p>
				<p>
					<a href="/downloads/archiv/Ostara Programm 2010.pdf" style={{ color: "#336" }}>
						<em>Programm herunterladen</em>
					</a>
				</p>
			</>
		),
		NL: (
			<>
				<p>
					<em>18. - 21.03 in Bessunger Forst,</em>
					<br /> georganiseerd door Andreas Cushion, Christian Kordas en Hermann Ritter, in samenwerking met
					de Eldaring eV
				</p>
			</>
		),
		EN: (
			<>
				<p>
					<em>18 - 21, 03 at Bessunger Forst,</em>
					<br /> organised by Andreas Cushion, Christian Kordas, and Hermann Ritter, in cooperation with
					Eldaring eV
				</p>
			</>
		),
	},
	2011: {
		DE: (
			<>
				<p>
					<em>17. - 20.03 auf Burg Bilstein,</em>
					<br />
					veranstaltet von Andreas Cushion, Christian Kordas und Hermann Ritter
				</p>
				<p>
					<a href="/downloads/archiv/Ostara Programm 2011.pdf" style={{ color: "#336" }}>
						<em>Programm herunterladen</em>
					</a>
				</p>
			</>
		),
		NL: (
			<>
				<p>
					<em>17. - 20.03 auf Burg Bilstein,</em>
					<br /> georganiseerd door Andreas Cushion, Christian Kordas en Hermann Ritter
				</p>
			</>
		),
		EN: (
			<>
				<p>
					<em>17 - 20, 03 at Burg Bilstein,</em>
					<br /> organised by Andreas Cushion, Christian Kordas, and Hermann Ritter
				</p>
			</>
		),
	},
	2012: {
		DE: (
			<>
				<p>
					<em>15. - 18.03 auf Burg Bilstein,</em>
					<br />
					veranstaltet von Andreas Cushion, Christian Kordas und Hermann Ritter, in Kooperation mit dem
					Eldaring eV
				</p>
				<p>
					<a href="/downloads/archiv/Ostara Programm 2012.pdf" style={{ color: "#336" }}>
						<em>Programm herunterladen</em>
					</a>
					&nbsp;&nbsp;&nbsp;&nbsp;
					<a href="/downloads/archiv/Ostara 2012 Heidnischer Herold.pdf" style={{ color: "#336" }}>
						<em>Artikel aus dem Heidnischen Herold</em>
					</a>
				</p>
			</>
		),
		NL: (
			<>
				<p>
					<em>15. - 18.03 op Burg Bilstein,</em>
					<br /> georganiseerd door Andreas Cushion, Christian Kordas en Hermann Ritter, in samenwerking met
					de Eldaring eV
				</p>
			</>
		),
		EN: (
			<>
				<p>
					<em>15 - 18, 03 at Burg Bilstein,</em>
					<br /> organised by Andreas Cushion, Christian Kordas, and Hermann Ritter, in cooperation with
					Eldaring eV
				</p>
			</>
		),
	},
	2013: {
		DE: (
			<>
				<p>
					<em>21. - 24.03 auf der Tonenburg,</em>
					<br />
					veranstaltet von Andreas Cushion und Christian Kordas
				</p>
				<p>
					Nach der Veranstaltung 2013 entschied sich Christian aus persönlichen Gründen nicht mehr in der
					Ostara Organisation mitzuwirken. Das darauffolgende Jahr wurde Ostara auf der Burg Bilstein von
					Andreas und Hermann ausgerichtet.
				</p>
				<p>
					<a href="/downloads/archiv/Ostara Programm 2013.pdf" style={{ color: "#336" }}>
						<em>Programm herunterladen</em>
					</a>
				</p>
			</>
		),
		NL: (
			<>
				<p>
					<em>21. - 24.03 op de Tonenburg,</em>
					<br /> georganiseerd door Andreas Cushion en Christian Kordas
				</p>
				<p>
					Na het evenement in 2013 besloot Christian om persoonlijke redenen niet meer deel te nemen aan de
					Ostara-organisatie. Het jaar daarop werd Ostara georganiseerd door Andreas en Hermann in het kasteel
					Bilstein.
				</p>
			</>
		),
		EN: (
			<>
				<p>
					<em>21 - 24, 03 at the Tonenburg,</em>
					<br /> organised by Andreas Cushion and Christian Kordas
				</p>
				<p>
					After the event in 2013 for personal reasons Christian decided not to participate any longer in the
					Ostara organization. From the following year, Ostara was then hosted at the castle Bilstein by
					Andreas (Cushion) and Hermann.
				</p>
			</>
		),
	},
	2014: {
		DE: (
			<>
				<p>
					<em>24. - 27.04. auf Burg Bilstein,</em>
					<br />
					veranstaltet von Andreas Cushion und Hermann Ritter
				</p>
				<h3>Die Kneipe am Kreuzweg</h3>
				<p>
					Nach Ostara 2014 saßen Hermann und Andreas mit ihren Lebensgefährtinnen Petra Bolte und Ravena
					Brenneken zusammen und besprachen wie man Ostara neu auflegen und der Veranstaltung einen neuen
					Anstrich geben könnte. Es entstand die Idee Ostara als einen imaginären Ort des Weltenbaumes
					Yggdrasils zu platzieren, von wo aus jedes Jahr eine neue Welt des Weltenbaumes Thema wird:{" "}
					<em>Die Kneipe am Kreuzweg.</em>
				</p>
				<p>
					<a href="/downloads/archiv/Ostara Programm 2014.pdf" style={{ color: "#336" }}>
						<em>Programm herunterladen</em>
					</a>
				</p>
			</>
		),
		NL: (
			<>
				<p>
					<em>24. - 27.04. bij Kasteel Bilstein,</em>
					<br /> georganiseerd door Andreas Cushion en Hermann Ritter
				</p>
				<h3>De pub op het kruispunt</h3>
				<p>
					Na het Ostara festival in 2014 bespraken Hermann en Andreas samen met hun levenspartners Petra Bolte
					en Ravena Brenneken hoe ze Ostara konden vernieuwen om het evenement een nieuwe look te kunnen
					geven. Het idee onstond om Ostara te zien als een denkbeeldige plaats van de wereldboom Yggdrasil,
					van waaruit elk jaar een nieuwe wereld van de wereldboom het thema wordt: En zo werd het 'de pub op
					het kruispunt'.
				</p>
			</>
		),
		EN: (
			<>
				<p>
					<em>24 - 27, 04 at Castle Bilstein,</em>
					<br /> organised by Andreas Cushion and Hermann Ritter
				</p>
				<h3>Pub at the Crossroads</h3>
				<p>
					After the Ostara festival in 2014, Hermann and Andreas sat together with their partners Petra Bolte
					and Ravena Brenneken and discussed how to renew the Ostara event and give it a new look. The idea to
					place Ostara at an imaginary place on the world tree Yggdrasil, and then every year another world of
					Yggdrasil would become the central theme: That virtual place was called "The pub at the crossroads".
				</p>
			</>
		),
	},
	2015: {
		DE: (
			<>
				<h3>Vanaheim</h3>
				<p>
					<em>16. - 19.04 auf Burg Hessenstein,</em>
					<br />
					veranstaltet von Ravena Brenneken, Petra Bolte, Andreas Cushion und Hermann Ritter, in Kooperation
					mit dem Eldaring eV
				</p>
				<p>
					Im Jahr 2015 wurde die <em>Lokis Lapsus Gbr</em> für die Veranstaltung gegründet. Des Weiteren
					konnten wir Hendrik Belitz als Webmaster für die Ostara-Veranstaltungen gewinnen. Christian
					„Krischan“ Brüning designte das Veranstaltungsemblem und die dazugehörigen Weltenpatches für die
					jeweilige Jahresveranstaltung.
				</p>
				<p>
					Geplant sind insgesamt zehn Jahre: neun Welten in neun Jahren und einen großen Abschluss im zehnten
					Jahr.{" "}
				</p>
				<p>
					Unser Anspruch ist unseren Gästen eine lockere zwanglose Atmosphäre auf den Veranstaltungen zu
					präsentieren, wo jeder für sich das passende im Programmangebot findet. Wir sind bestrebt
					wissenschaftliche und quellenfundierte Vorträge, praxisorientierte Workshops und gute Unterhaltung
					anzubieten.
				</p>
				<p>
					Extrempolitische oder rassistische Aktivitäten werden auf der Ostara-Veranstaltung nicht geduldet.
					Wir nehmen in diesem Fall unser Hausrecht in Anspruch und verweisen die Betroffenen von der
					Veranstaltung und Burg. Für uns gilt das Selbstverständnis des Eldaring e.V.
				</p>
				<p>
					<a href="/downloads/archiv/Ostara Programm 2015.pdf" style={{ color: "#336" }}>
						<em>Programm herunterladen</em>
					</a>
				</p>
			</>
		),
		NL: (
			<>
				<h3>Vanaheim</h3>
				<p>
					<em>16. - 19.04 op Burg Hessenstein,</em>
					<br />
					georganiseerd door Ravena Brenneken, Petra Bolte, Andreas Cushion en Hermann Ritter, in samenwerking
					met de Eldaring eV
				</p>
				<p>
					In 2015 werd de “Lokis Lapsus Gbr” speciaal voor het Ostara evenement opgericht (Gbr is een Duitse
					wettelijk geregistreerde organisatievorm). Bovendien hebben we Hendrik Belitz kunnen winnen als
					webmaster voor de Ostara-evenementen. Christian "Krischan" Brüning ontwierp het evenementembleem en
					de bijbehorende wereld-insignes voor het betreffende jaarlijkse evenement.
				</p>
				<p>
					In totaal is er tien jaar gepland: negen werelden in negen jaar en een grote afsluiting in het
					tiende jaar.
				</p>
				<p>
					Ons doel is om onze gasten een informele ongedwongen sfeer te bieden tijdens de evenementen, en waar
					iedereen iets geschikts voor zichzelf kan vinden in het programma. We streven ernaar om
					wetenschappelijk gefundeerde, op bronnen gebaseerde lezingen, praktische workshops aan te bieden en
					daarnaast ook goed amusement.
				</p>
				<p>
					Extreme politieke of racistische activiteiten worden niet getolereerd tijdens het Ostara-evenement.
					In dit geval beroepen we ons als gastheren op het verblijfsrecht en de betroffen personen worden van
					verdere deelneming aan het feest uitgesloten en de toegang tot het kasteel ontzegd. Voor ons is het
					zelfbeeld van Eldaring e.V. maatgevend.
				</p>
			</>
		),
		EN: (
			<>
				<h3>Vanaheim</h3>
				<p>
					<em>16 - 19, 04 at Burg Hessenstein,</em>
					<br />
					organised by Ravena Brenneken, Petra Bolte, Andreas Cushion, and Hermann Ritter, in cooperation with
					Eldaring eV
				</p>
				<p>
					In 2015, especially for the organization of the event the "Lokis Lapsus Gbr" (a company constituted
					under civil law) was founded. Furthermore, we were able to aquire Hendrik Belitz as the webmaster
					for our Ostara events. Christian "Krischan" Brüning designed the event emblem and the associated
					'world patches' for the respective annual event.{" "}
				</p>
				<p>
					A total of ten festivals are planned: nine worlds in nine years and a big finalizing event in the
					tenth year.
				</p>
				<p>
					Our aim is to present to our guests a casual informal atmosphere at these events and where everyone
					can find something of interest to suit them on the program. We strive to provide scientific and
					source-based lectures, hands-on workshops and good entertainment.
				</p>
				<p>
					Extreme political or racist activities will not be tolerated at the Ostara event. In such cases, we
					claim our house right and will expel people from the event and the castle. For us, the
					self-conception the of Eldaring organization applies.
				</p>
			</>
		),
	},
	2016: {
		DE: (
			<>
				<h3>Asgard</h3>
				<p>
					<em>14. - 17.04 auf Burg Ludwigstein,</em>
					<br />
					veranstaltet von Ravena Brenneken, Petra Bolte, Andreas Cushion und Hermann Ritter
				</p>
				<p>
					<a href="/downloads/archiv/Ostara Programm 2016.pdf" style={{ color: "#336" }}>
						<em>Programm herunterladen</em>
					</a>
				</p>
			</>
		),
		NL: (
			<>
				<h3>Asgard</h3>
				<p>
					<em>14. - 17.04 in Burg Ludwigstein,</em>
					<br /> georganiseerd door Ravena Brenneken, Petra Bolte, Andreas Cushion en Hermann Ritter
				</p>
			</>
		),
		EN: (
			<>
				<h3>Asgard</h3>
				<p>
					<em>14 - 17, 04 at Burg Ludwigstein,</em>
					<br /> organised by Ravena Brenneken, Petra Bolte, Andreas Cushion, and Hermann Ritter
				</p>
			</>
		),
	},
	2017: {
		DE: (
			<>
				<h3>Niflheim</h3>
				<p>
					<em>30. - 02.04 auf Burg Ludwigstein,</em>
					<br />
					veranstaltet von Ravena Brenneken, Petra Bolte, Andreas Cushion und Hermann Ritter, in Kooperation
					mit dem Eldaring eV
				</p>
				<p>Wir bedauern sehr den viel zu frühen Tod von Krischan.</p>
				<p>
					<a href="/downloads/archiv/Ostara Programm 2017.pdf" style={{ color: "#336" }}>
						<em>Programm herunterladen</em>
					</a>
				</p>
			</>
		),
		NL: (
			<>
				<h3>Niflheim</h3>
				<p>
					<em>30. - 02.04 op Burg Ludwigstein,</em>
					<br /> georganiseerd door Ravena Brenneken, Petra Bolte, Andreas Cushion en Hermann Ritter, in
					samenwerking met de Eldaring eV
				</p>
				<p>We betreuren ten zeerste de voortijdige dood van Krischan.</p>
			</>
		),
		EN: (
			<>
				<h3>Niflheim</h3>
				<p>
					<em>30 - 02, 04 op Burg Ludwigstein,</em>
					<br /> organised by Ravena Brenneken, Petra Bolte, Andreas Cushion, and Hermann Ritter, in
					cooperation with the Eldaring eV
				</p>
				<p>We were very saddened by the sudden death of Krischan.</p>
			</>
		),
	},
	2018: {
		DE: (
			<>
				<h3>Lichtalbenheim</h3>
				<p>
					<em>05. - 08.04 auf Burg Ludwigstein,</em>
					<br />
					veranstaltet von Ravena Brenneken, Petra Bolte, Andreas Cushion und Hermann Ritter, in Kooperation
					mit dem Eldaring eV
				</p>
				<p>Ab 2018 übernahm Katrin Mang freundlicherweise die Aufgabe der Patch-Erstellung.</p>
				<p>
					<a href="/downloads/archiv/Ostara Programm 2018.pdf" style={{ color: "#336" }}>
						<em>Programm herunterladen</em>
					</a>
				</p>
			</>
		),
		NL: (
			<>
				<h3>Lichtalbenheim</h3>
				<p>
					<em>05. - 08.04 op Burg Ludwigstein,</em>
					<br />
					georganiseerd door Ravena Brenneken, Petra Bolte, Andreas Cushion en Hermann Ritter, in samenwerking
					met de Eldaring eV
				</p>
				<p>Vanaf 2018 nam Katrin Mang deze taak welwillend op zich.</p>
			</>
		),
		EN: (
			<>
				<h3>Lichtalbenheim</h3>
				<p>
					<em>05 - 08, 04 at Burg Ludwigstein,</em>
					<br />
					organised by Ravena Brenneken, Petra Bolte, Andreas Cushion, and Hermann Ritter, in cooperation with
					Eldaring eV
				</p>
				<p>From 2018 Katrin Mang kindly took over the task of creating the patches.</p>
			</>
		),
	},
	2019: {
		DE: (
			<>
				<h3>Ostara trifft Beltaine</h3>
				<p>
					<em>01. - 05.05 auf Burg Ludwigstein,</em>
					<br />
					veranstaltet von Ravena Brenneken, Petra Bolte, Andreas Cushion und Hermann Ritter, in Kooperation
					mit dem Eldaring eV
				</p>
				<p>
					<a href="/downloads/archiv/Ostara Programm 2019.pdf" style={{ color: "#336" }}>
						<em>Programm herunterladen</em>
					</a>
				</p>
			</>
		),
		NL: (
			<>
				<h3>Ostara trifft Beltaine</h3>
				<p>
					<em>01. - 05.05 op Burg Ludwigstein,</em>
					<br />
					georganiseerd door Ravena Brenneken, Petra Bolte, Andreas Cushion en Hermann Ritter, in samenwerking
					met de Eldaring eV
				</p>
			</>
		),
		EN: (
			<>
				<h3>Ostara meets Beltaine</h3>
				<p>
					<em>01 - 05, 05 at Burg Ludwigstein,</em>
					<br />
					organised by Ravena Brenneken, Petra Bolte, Andreas Cushion, and Hermann Ritter, in cooperation with
					Eldaring eV
				</p>
			</>
		),
	},
	2020: {
		DE: (
			<>
				<h3>Corona</h3>
				<p>Liebe Gäste,</p>
				<p>
					leider hat die Burg Ludwigstein inzwischen die Tore bis zum 19.04.2020 geschlossen (siehe&nbsp;
					<a
						style={{ color: "#336" }}
						href="https://www.burgludwigstein.de/mitmachen/burg/detail/aktuelle-information"
					>
						hier
					</a>
					). Damit muss Ostara 2020 ausfallen, weil wir keinen Veranstaltungsort haben.
				</p>
				<p>
					Formal heißt das, dass wir ein Jahr „unterbrechen“ und ein Jahr länger machen, um die
					10-Jahres-Runde voll zu kriegen.
				</p>
				<p>
					Menschlich heißt das, dass wir uns alle nicht sehen werden. Das ist für uns der schlimmste Teil der
					ganzen Sache.
				</p>
				<p>
					Die Burg wird uns die Anzahlungen zurücküberweisen. Das kann sich ein paar Tage hinziehen, danach
					informieren wir euch und organisieren die Rücküberweisungen. Wir möchten euch bitten, darauf zu
					vertrauen, dass wir auf euch zukommen – die vielen Rückfragen und „klugen Hinweise“ der letzten Tage
					sind verständlich, aber sie machen es uns nicht einfacher.
				</p>
				<p>
					Natürlich gibt es Menschen, die unter der Absage von Ostara mehr leiden müssen als andere. Daher
					unser Aufruf: Nutzt das gesparte Geld für Anreise und andere Dinge, und besucht die Shops unserer
					Händler, um vielleicht jenen finanziell ein wenig unter die Arme zu greifen, die nach dem Ausfall
					von diversen Veranstaltungen jetzt Existenznot haben:
				</p>
				<p>
					<a style={{ color: "#336" }} href="https://shop.mittgard.de/">
						https://shop.mittgard.de/
					</a>
				</p>
				<p>
					<a style={{ color: "#336" }} href="https://beowulf-schleswig.de/">
						https://beowulf-schleswig.de/
					</a>
				</p>
				<p>
					<a style={{ color: "#336" }} href="https://www.roterdrache.org/cms/">
						https://www.roterdrache.org/cms/
					</a>
				</p>
				<p>
					Wir freuen uns darauf, dass wir uns hoffentlich alle 2021 wieder sehen! Bleibt gesund, achtet auf
					eure Mitmenschen sowie euch selbst und sumbelt darauf, dass die Zeiten wieder gut werden.
				</p>
				<p>
					<br />
					Gruß, die Ostara-Crew
				</p>
				<p>&nbsp;Cushy, Hermann, Petra, Ravena</p>
			</>
		),
		NL: (
			<>
				<h3>Corona</h3>
				<p>Liebe Gäste,</p>
				<p>
					leider hat die Burg Ludwigstein inzwischen die Tore bis zum 19.04.2020 geschlossen (siehe
					<a
						style={{ color: "#336" }}
						href="https://www.burgludwigstein.de/mitmachen/burg/detail/aktuelle-information"
					>
						hier
					</a>
					). Damit muss Ostara 2020 ausfallen, weil wir keinen Veranstaltungsort haben.
				</p>
				<p>
					Formal heißt das, dass wir ein Jahr „unterbrechen“ und ein Jahr länger machen, um die
					10-Jahres-Runde voll zu kriegen.
				</p>
				<p>
					Menschlich heißt das, dass wir uns alle nicht sehen werden. Das ist für uns der schlimmste Teil der
					ganzen Sache.
				</p>
				<p>
					Die Burg wird uns die Anzahlungen zurücküberweisen. Das kann sich ein paar Tage hinziehen, danach
					informieren wir euch und organisieren die Rücküberweisungen. Wir möchten euch bitten, darauf zu
					vertrauen, dass wir auf euch zukommen – die vielen Rückfragen und „klugen Hinweise“ der letzten Tage
					sind verständlich, aber sie machen es uns nicht einfacher.
				</p>
				<p>
					Natürlich gibt es Menschen, die unter der Absage von Ostara mehr leiden müssen als andere. Daher
					unser Aufruf: Nutzt das gesparte Geld für Anreise und andere Dinge, und besucht die Shops unserer
					Händler, um vielleicht jenen finanziell ein wenig unter die Arme zu greifen, die nach dem Ausfall
					von diversen Veranstaltungen jetzt Existenznot haben:
				</p>
				<p>
					<a style={{ color: "#336" }} href="https://shop.mittgard.de/">
						https://shop.mittgard.de/
					</a>
				</p>
				<p>
					<a style={{ color: "#336" }} href="https://beowulf-schleswig.de/">
						https://beowulf-schleswig.de/
					</a>
				</p>
				<p>
					<a style={{ color: "#336" }} href="https://www.roterdrache.org/cms/">
						https://www.roterdrache.org/cms/
					</a>
				</p>
				<p>
					Wir freuen uns darauf, dass wir uns hoffentlich alle 2021 wieder sehen! Bleibt gesund, achtet auf
					eure Mitmenschen sowie euch selbst und sumbelt darauf, dass die Zeiten wieder gut werden.
				</p>
				<p>
					<br />
					Gruß, die Ostara-Crew
				</p>
				<p>&nbsp;Cushy, Hermann, Petra, Ravena</p>
			</>
		),
		EN: (
			<>
				<h3>Corona</h3>
				<p>Liebe Gäste,</p>
				<p>
					leider hat die Burg Ludwigstein inzwischen die Tore bis zum 19.04.2020 geschlossen (siehe
					<a
						style={{ color: "#336" }}
						href="https://www.burgludwigstein.de/mitmachen/burg/detail/aktuelle-information"
					>
						hier
					</a>
					). Damit muss Ostara 2020 ausfallen, weil wir keinen Veranstaltungsort haben.
				</p>
				<p>
					Formal heißt das, dass wir ein Jahr „unterbrechen“ und ein Jahr länger machen, um die
					10-Jahres-Runde voll zu kriegen.
				</p>
				<p>
					Menschlich heißt das, dass wir uns alle nicht sehen werden. Das ist für uns der schlimmste Teil der
					ganzen Sache.
				</p>
				<p>
					Die Burg wird uns die Anzahlungen zurücküberweisen. Das kann sich ein paar Tage hinziehen, danach
					informieren wir euch und organisieren die Rücküberweisungen. Wir möchten euch bitten, darauf zu
					vertrauen, dass wir auf euch zukommen – die vielen Rückfragen und „klugen Hinweise“ der letzten Tage
					sind verständlich, aber sie machen es uns nicht einfacher.
				</p>
				<p>
					Natürlich gibt es Menschen, die unter der Absage von Ostara mehr leiden müssen als andere. Daher
					unser Aufruf: Nutzt das gesparte Geld für Anreise und andere Dinge, und besucht die Shops unserer
					Händler, um vielleicht jenen finanziell ein wenig unter die Arme zu greifen, die nach dem Ausfall
					von diversen Veranstaltungen jetzt Existenznot haben:
				</p>
				<p>
					<a style={{ color: "#336" }} href="https://shop.mittgard.de/">
						https://shop.mittgard.de/
					</a>
				</p>
				<p>
					<a style={{ color: "#336" }} href="https://beowulf-schleswig.de/">
						https://beowulf-schleswig.de/
					</a>
				</p>
				<p>
					<a style={{ color: "#336" }} href="https://www.roterdrache.org/cms/">
						https://www.roterdrache.org/cms/
					</a>
				</p>
				<p>
					Wir freuen uns darauf, dass wir uns hoffentlich alle 2021 wieder sehen! Bleibt gesund, achtet auf
					eure Mitmenschen sowie euch selbst und sumbelt darauf, dass die Zeiten wieder gut werden.
				</p>
				<p>
					<br />
					Gruß, die Ostara-Crew
				</p>
				<p>&nbsp;Cushy, Hermann, Petra, Ravena</p>
			</>
		),
	},
	2021: {
		DE: (
			<>
				<p>Liebe Ostara Freunde,</p>
				<p>
					die aktuelle Situation um das Corona Virus ließ nichts gutes über die Durchführbarkeit unserer
					Veranstaltung vermuten.
				</p>

				<p>Am 24.03.2021 haben wir schriftlich die Absage der Burg Ludwigstein erhalten.</p>

				<p>Somit ist das diesjährige Ostara offiziell abgesagt.</p>

				<p>Weitere Informationen werden wir zeitnah bekannt geben.</p>

				<p>Bleibt gesund,</p>

				<p>Eure Ostara Orga</p>
			</>
		),
		NL: (
			<>
				<p>Liebe Ostara Freunde,</p>
				<p>
					die aktuelle Situation um das Corona Virus ließ nichts gutes über die Durchführbarkeit unserer
					Veranstaltung vermuten.
				</p>

				<p>Am 24.03.2021 haben wir schriftlich die Absage der Burg Ludwigstein erhalten.</p>

				<p>Somit ist das diesjährige Ostara offiziell abgesagt.</p>

				<p>Weitere Informationen werden wir zeitnah bekannt geben.</p>

				<p>Bleibt gesund,</p>

				<p>Eure Ostara Orga</p>
			</>
		),
		EN: (
			<>
				<p>Liebe Ostara Freunde,</p>
				<p>
					die aktuelle Situation um das Corona Virus ließ nichts gutes über die Durchführbarkeit unserer
					Veranstaltung vermuten.
				</p>

				<p>Am 24.03.2021 haben wir schriftlich die Absage der Burg Ludwigstein erhalten.</p>

				<p>Somit ist das diesjährige Ostara offiziell abgesagt.</p>

				<p>Weitere Informationen werden wir zeitnah bekannt geben.</p>

				<p>Bleibt gesund,</p>

				<p>Eure Ostara Orga</p>
			</>
		),
	},
	2022: {
		DE: (
			<>
				<h3>Reise nach Schwarzalbenheim</h3>
				<p>
					<em>21. - 24.04 auf Burg Ludwigstein,</em>
					<br />
					veranstaltet von Lokis Lapsus GbR, in Kooperation mit dem Eldaring e.V.
				</p>
				<p>
					<a href="/downloads/archiv/Ostara Programm 2022.pdf" style={{ color: "#336" }}>
						<em>Programm herunterladen</em>
					</a>
				</p>
			</>
		),
		EN: (
			<>
				<h3>Reise nach Schwarzalbenheim</h3>
				<p>
					<em>21. - 24.04 auf Burg Ludwigstein,</em>
					<br />
					veranstaltet von Lokis Lapsus GbR, in Kooperation mit dem Eldaring e.V.
				</p>
				<p>
					<a href="/downloads/archiv/Ostara Programm 2022.pdf" style={{ color: "#336" }}>
						<em>Programm herunterladen</em>
					</a>
				</p>
			</>
		),
		NL: (
			<>
				<h3>Reise nach Schwarzalbenheim</h3>
				<p>
					<em>21. - 24.04 auf Burg Ludwigstein,</em>
					<br />
					veranstaltet von Lokis Lapsus GbR, in Kooperation mit dem Eldaring e.V.
				</p>
				<p>
					<a href="/downloads/archiv/Ostara Programm 2022.pdf" style={{ color: "#336" }}>
						<em>Programm herunterladen</em>
					</a>
				</p>
			</>
		),
	},
	2023: {
		DE: (
			<>
				<h3>Reise nach Jötunheim</h3>
				<p>
					<em>13. - 16.04 auf Burg Ludwigstein,</em>
					<br />
					veranstaltet von Lokis Lapsus GbR, in Kooperation mit dem Eldaring e.V.
				</p>
				<p>
					<a href="/downloads/archiv/Ostara Programm 2023.pdf" style={{ color: "#336" }}>
						<em>Programm herunterladen</em>
					</a>
				</p>
			</>
		),
		EN: (
			<>
				<h3>Reise nach Jötunheim</h3>
				<p>
					<em>13. - 16.04 auf Burg Ludwigstein,</em>
					<br />
					veranstaltet von Lokis Lapsus GbR, in Kooperation mit dem Eldaring e.V.
				</p>
				<p>
					<a href="/downloads/archiv/Ostara Programm 2023.pdf" style={{ color: "#336" }}>
						<em>Programm herunterladen</em>
					</a>
				</p>
			</>
		),
		NL: (
			<>
				<h3>Reise nach Jötunheim</h3>
				<p>
					<em>13. - 16.04 auf Burg Ludwigstein,</em>
					<br />
					veranstaltet von Lokis Lapsus GbR, in Kooperation mit dem Eldaring e.V.
				</p>
				<p>
					<a href="/downloads/archiv/Ostara Programm 2023.pdf" style={{ color: "#336" }}>
						<em>Programm herunterladen</em>
					</a>
				</p>
			</>
		),
	},
	2024: {
		DE: (
			<>
				<h3>Reise nach Helheim</h3>
				<p>
					<em>04. - 07.04 auf Burg Ludwigstein,</em>
					<br />
					veranstaltet von Lokis Lapsus GbR, in Kooperation mit dem Eldaring e.V.
				</p>
				<p>
					<a href="/downloads/archiv/Ostara Programm 2024.pdf" style={{ color: "#336" }}>
						<em>Programm herunterladen</em>
					</a>
				</p>
			</>
		),
		EN: (
			<>
				<h3>Reise nach Helheim</h3>
				<p>
					<em>04. - 07.04 auf Burg Ludwigstein,</em>
					<br />
					veranstaltet von Lokis Lapsus GbR, in Kooperation mit dem Eldaring e.V.
				</p>
				<p>
					<a href="/downloads/archiv/Ostara Programm 2024.pdf" style={{ color: "#336" }}>
						<em>Programm herunterladen</em>
					</a>
				</p>
			</>
		),
		NL: (
			<>
				<h3>Reise nach Helheim</h3>
				<p>
					<em>04. - 07.04 auf Burg Ludwigstein,</em>
					<br />
					veranstaltet von Lokis Lapsus GbR, in Kooperation mit dem Eldaring e.V.
				</p>
				<p>
					<a href="/downloads/archiv/Ostara Programm 2024.pdf" style={{ color: "#336" }}>
						<em>Programm herunterladen</em>
					</a>
				</p>
			</>
		),
	},
};

const Chronik = (props: { show: boolean; onClose: () => void }) => {
	const [value, setValue] = useState(0);
	const lang = useContext(CRM).language;
	return (
		<Modal show={props.show} onClose={props.onClose} title={crm[lang].title}>
			<div style={{ width: "100%", height: "80px", margin: "0 auto" }}>
				<HorizontalTimeline
					index={value}
					// tslint:disable-next-line:jsx-no-lambda
					indexClick={(index: number) => {
						setValue(index);
					}}
					values={VALUES}
					// tslint:disable-next-line:jsx-no-lambda
					getLabel={(label: string) => label}
				/>
			</div>
			<div style={{ height: window.innerHeight - 380, overflowY: "auto" }}>{chronic[VALUES[value]][lang]}</div>
		</Modal>
	);
};

const crm: any = {
	DE: { title: "Chronik" },
	NL: { title: "Kroniek" },
	EN: { title: "History of events" },
};

export default Chronik;
