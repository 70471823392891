import React, { useContext } from "react";
import { Link } from "react-router-dom";

import CRM from "./CRM";

const showFlags = (language: string) => {
    const flags = [];
    if (language !== "DE") {
        flags.push(<li>
            <Link to="/">
                <a><img src="/images/flag_de.png" alt="Deutsche Website" height="24px"/></a>
            </Link>
        </li>);
    }
    if (language !== "EN") {
        flags.push(<li>
            <Link to="/en">
                <a><img src="/images/flag_uk.png" alt="English website" height="24px"/></a>
            </Link>
        </li>);
    }
    if (language !== "NL") {
        flags.push(<li>
            <Link to="/nl">
                <a><img src="/images/flag_nl.png" alt="Nederlandse website" height="24px"/></a>
            </Link>
        </li>);
    }
    return <>{flags}</>;
};

const Header = () => {
    const lang = useContext(CRM).language;
    return <header id="header">
        <h1 id="logo">OSTARA</h1>
        <nav id="nav">
            <ul>
                <li><a href="#intro">Ostara</a></li>
                <li><a href="#one">{crm[lang].Veranstaltung}</a></li>
                <li><a href="#two">{crm[lang].Anmeldung}</a></li>
                <li><a href="#three">Eldaring</a></li>
                <li><a href="#four">{crm[lang].Impressionen}</a></li>
                <li><a href="#contact">{crm[lang].Kontakt}</a></li>
                <li><a href="#impressum">{crm[lang].Impressum}</a></li>
                {showFlags(lang)}
            </ul>
        </nav>
    </header>;
};

const crm: any = {
    DE: {
        Veranstaltung: "Veranstaltung",
        Anmeldung: "Anmeldung",
        Impressionen: "Impressionen",
        Kontakt: "Kontakt",
        Impressum: "Impressum",
    },
    EN: {
        Veranstaltung: "Event",
        Anmeldung: "Registration",
        Impressionen: "Gallery",
        Kontakt: "Contact",
        Impressum: "Legal notice",
    },
    NL: {
        Veranstaltung: "Evenement",
        Anmeldung: "Aanmelden",
        Impressionen: "Impressies",
        Kontakt: "Contact",
        Impressum: "Juridische Mededeling",
    }
};

export default Header;
