import React, { useContext } from "react";
import { classes, style } from "typestyle";
import CRM from "../CRM";

interface ModalProps {
    show: boolean;
    onClose: () => void;
    title: string;
    children: React.ReactNode[];
}

const modalDialog = style({
    backgroundColor: "#eee",
    position: "relative",
    top: 100
});

const modalBody = style({
    maxHeight: window.innerHeight - 300,
    overflowY: "auto",
});

const modalFont = style({
    color: "#39454b",
});

const Modal = (props: ModalProps) => {
    const lang = useContext(CRM).language;
    return props.show ? <div className={classes("modal show fade in", modalFont)}>
        <div className={classes("modal-dialog modal-lg", modalDialog)}>
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title">{props.title}</h4>
                </div>
                <div className={classes("modal-body", modalBody)}>
                    {props.children}
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-default" onClick={props.onClose}><b>{crm[lang]}</b></button>
                </div>
            </div>
        </div>
    </div> : null;
};

const crm = {
    DE: "Schließen",
    EN: "Close",
    NL: "Sluiten",
};

export default Modal;
