import React, { useContext } from "react";

import CRM from "./CRM";
import { SectionProps } from "./SectionProps";

const Intro = (props: SectionProps) => {
    const lang = useContext(CRM).language;
    return <section id={props.id} className="main style1 dark fullscreen">
        <div className="content container 100%">
            <header>
                <h2>Ostara</h2>
            </header>
            <p><b>{crm[lang].Ostara}</b></p>
            <footer>
                <a href={`#${props.next}`} className="button style2 down">More</a>
            </footer>
        </div>
    </section>;
};

const crm: any = {
    DE: {
        Ostara: <>Das heidnische Ostern zu Ehren der wiederkehrenden Fruchtbarkeit, des Wachstums
        und der wiedererwachenden Natur findet um die Frühlings-Tag-und-Nachtgleiche Ende März,
        oder im anschließenden <em>Ostermonat</em> April statt.</>
    },
    EN: {
        Ostara: <>Ostara is the Pagan celebration of Easter to honor fertility, growth and regeneration
        at the Spring Equinox, at the end of March or the beginning of April.</>
    },
    NL: {
        Ostara: <>Het heidense Pasen, waarbij we de terugkerende vruchtbaarheid, de groei en de weer
        oplevende natuur eren, vindt plaats rond de lente-dag-en-nacht-evening aan het einde van maart
        of in de aansluitende <em>Paasmaand</em> april.</>
    }
};

export default Intro;
