import React, { useContext, useState } from "react";
import { classes } from "typestyle";
import Chronik from "./Chronik";
import Modal from "./components/Modal";
import CRM from "./CRM";
import { SectionProps } from "./SectionProps";
import { callToAction } from "./styles";

// tslint:disable:jsx-no-lambda

const Veranstaltung = (props: SectionProps) => {
	const [showTermine, setShowTermine] = useState(false);
	const [showChronik, setShowChronik] = useState(false);
	const lang = useContext(CRM).language;

	return (
		<section id={props.id} className="main style2 right dark fullscreen">
			{showChronik && <Chronik show={showChronik} onClose={() => setShowChronik(false)} />}
			<Modal show={showTermine} onClose={() => setShowTermine(false)} title={crm[lang].modalTitle}>
				{crm[lang].modalContent}
			</Modal>
			<div className="content box style2">
				<header>
					<h2>{crm[lang].Veranstaltung}</h2>
				</header>
				{crm[lang].content}
				<p>
					<a className={classes("button", callToAction)} onClick={() => setShowTermine(true)}>
						{crm[lang].Termine}
					</a>
					&nbsp;
					<a className={classes("button", callToAction)} onClick={() => setShowChronik(true)}>
						{crm[lang].Chronik}
					</a>
				</p>
			</div>
			<a href={`#${props.next}`} className="button style2 down dark anchored">
				Next
			</a>
		</section>
	);
};

const crm: any = {
	DE: {
		Veranstaltung: "Veranstaltung",
		content: (
			<p>
				In diesem Zeitraum gibt es seit Jahren eine Veranstaltung, die zurzeit von Andreas Cushion und Hermann
				Ritter organisiert wird. Seit 2015 steht die jährliche Ostara-Veranstaltung unter dem Motto{" "}
				<em>Die Kneipe am Kreuzweg</em>. Die Kneipe am Kreuzweg ist ein imaginärer Ort zwischen den neun Welten,
				irgendwo im Schatten von Yggdrasil. Von dort aus <em>reist</em> die Ostara-Veranstaltung jedes Frühjahr
				in eine der neun Welten der germanischen Mythologie. Geplant sind insgesamt zehn Jahre: neun Welten in
				neun Jahren und einen großen Abschluss im zehnten Jahr.
			</p>
		),
		Termine: "Termine",
		Chronik: "Chronik",
		modalTitle: "Veranstaltungstermine und -orte.",
		modalContent: (
			<>
				<p>
					Austragungsort ist die{" "}
					<a href="http://www.burgludwigstein.de/" style={{ color: "#336" }}>
						Burg Ludwigstein
					</a>
				</p>
				<ul>
					<li>24.04. - 27.04.25 Reise nach Midgard</li>
					<li>29.04. - 03.05.26 Abschluss der Weltenreise. Ankommen in der Kneipe am Kreuzweg</li>
				</ul>
			</>
		),
	},
	EN: {
		Veranstaltung: "Event",
		content: (
			<p>
				Andreas Cushion and Hermann Ritter have organised an Ostara event to celebrate this time of the year.
				Since 2015 this event has been known as The Pub on the Way to the Cross. This is an imaginary place
				between the nine worlds, somewhere in the shadows of Yggdrasil. From here, the Ostara event will travel
				every spring into one of the nine worlds of Germanic mythology. A total of ten years are planned: nine
				worlds in nine years with a great conclusion in the tenth year.
			</p>
		),
		Termine: "Dates",
		Chronik: "Event history",
		modalTitle: "Event dates.",
		modalContent: (
			<>
				<p>
					The venue is{" "}
					<a href="http://www.burgludwigstein.de/" style={{ color: "#336" }}>
						Burg Ludwigstein
					</a>
					, Germany
				</p>
				<ul>
					<li>April 13th - April 16th 2023</li>
				</ul>
			</>
		),
	},
	NL: {
		Veranstaltung: "Evenement",
		content: (
			<p>
				In deze periode organiseren Andreas Cushion en Hermann Ritter al jarenlang een evenement. Sinds 2015
				heeft dit jaarlijkse Ostara-evenement als motto “de Kroeg aan het Kruispunt.” De Kroeg aan het Kruispunt
				is een denkbeeldige plaats tussen de negen werelden, ergens in de schaduw van de wereldboom Yggdrasil.
				Van daaruit 'reist' het Ostarafeest elk voorjaar naar één van de negen werelden van de Germaanse
				mythologie. Zo zijn bij elkaar tien jaren gepland: de negen werelden en één grootse afsluiting in het
				tiende jaar.
			</p>
		),
		Termine: "Data",
		Chronik: "Kroniek",
		modalTitle: "Data van het evenement.",
		modalContent: (
			<>
				<p>
					De locatie is{" "}
					<a href="http://www.burgludwigstein.de/" style={{ color: "#336" }}>
						Burg Ludwigstein
					</a>
					, Germany
				</p>
				<ul>
					<li>13 april - 16 april 2023</li>
				</ul>
			</>
		),
	},
};

export default Veranstaltung;
